<template>
  <div class="easter-egg chess" id="chess">
    <div class="chess__container">
      <div class="chess__header-square">
        <img src="@/assets/images/header_square.svg" alt="header_square">
      </div>
      <div class="chess__header-square--mobile">
        <img src="@/assets/images/header_square_mobile.svg" alt="header_square">
      </div>

      <div class="chess__logo-container">
        <div class="chess__logo">
          <img src="@/assets/images/logo.svg" alt="logo">
        </div>
      </div>
    </div>

    <div class="welcome chess__container">
      <div class="welcome__info">
        <div class="welcome__title">
          Добро пожаловать <br>в лучшее шахматное сообщество России!
        </div>
        <div class="welcome__text default-text">
          YouTube-канал Levitov Chess был открыт
          <span class="glue">в 2020</span>
          году шахматным
          энтузиастом Ильей
          Левитовым. Его миссия – познакомить всех желающих
          <span class="glue">с прекрасным</span>
          миром этой интеллектуальной игры, помочь окунуться
          <span class="glue">в ее</span> великую историю, познакомить
          <span class="glue">с судьбами</span> лучших игроков, быть
          <span class="glue">в курсе</span> современных событий,
          <span class="glue">а тех,</span>
          кто еще не умеет, научить играть
          <span class="glue">в шахматы!</span>
        </div>
        <div class="welcome__btn-container ">
<!--          <div class="welcome__btn-bg"/>-->
          <a href="https://youtube.com/c/LevitovChess?sub_confirmation=1"
             target="_blank"
             class="welcome__btn btn btn-white btn-animate"
             @click="joinYT"
          >
            Присоединиться
          </a>
        </div>
      </div>
      <div class="welcome__photo">
        <img src="@/assets/images/photo.png" alt="photo" @click="restorePage">
      </div>
    </div>

    <div class="welcome__photo--mobile">
      <img src="@/assets/images/photo.png" alt="photo">
    </div>

    <div easter-egg class="about chess__container">
      <div class="about__square">
        <img src="@/assets/images/about_square.svg" alt="about_square">
      </div>
      <div class="about__chess_field">
        <img src="@/assets/images/chess_field.svg" alt="chess_field">
      </div>
      <div class="click-me-30-times about__img" @click="onClick">
        <img class="click-me-30-times" src="@/assets/images/easter_egg.png" alt="chess">
      </div>
      <div class="about__content">
        <div class="about__text default-text">
          <div class="about__text-item-1">
            Levitov Chess – это место,
            <span class="glue">в котором</span> каждый найдет что-то свое. Актуальные новости,
            эксклюзивные сериалы
            <span class="glue">с участием</span> чемпионов мира: Анатолия Карпова, Гарри Каспарова
            <span class="glue">и Владимира</span> Крамника, интервью
            <span class="glue">с легендами</span> шахмат
            <span class="glue">и звездами</span> наших дней, стримы крупнейших турниров
            <span class="glue">в компании</span> лучших гроссмейстеров мира.
          </div>
          <br>
          <div class="about__text-item-2">
            Для желающих совершенствоваться
            <span class="glue">в нашей</span> Академии есть видеолекции, записанные
            ведущими тренерами России: как для начинающих так
            <span class="glue">и для тех,</span> кто выбрал шахматы своей профессией.
          </div>
          <div class="about__img--mobile">
            <img src="@/assets/images/chess_mobile.png" alt="chess">
          </div>
          <div class="about__text-item-3">
            Мы любим шахматы – играть, смотреть, говорить
            <span class="glue">о них.</span> Для нас
            <span class="glue">это не просто</span> хобби,
            это образ мышления, часть жизни. Люди, однажды полюбившие эту игру,
            <span class="glue">с ней не расстаются,</span>
            <span class="glue">не мыслят</span> себя без 64 клеток
            <span class="glue">и всего,</span> что
            <span class="glue">с ними</span> связано.
          </div>
        </div>
        <div class="about__invite">
          Присоединяйтесь
          <span class="glue">и получайте</span> удовольствие
          <span class="glue">от шахмат</span> вместе
          <span class="glue">с нами!</span>
        </div>
      </div>
    </div>

    <div class="slider-container">
      <YTSlider/>
    </div>

    <div class="btn-container">
      <a href="https://youtube.com/c/LevitovChess?sub_confirmation=1"
         target="_blank"
         class="welcome__btn btn btn-white btn-animate"
         @click="joinYT"
      >
        Присоединиться
      </a>
    </div>

    <div class="footer">
      <div class="footer__content chess__container">
        <div class="footer__text">
          Следите за турнирами
          <span class="glue">в соцсетях</span>
        </div>
        <div class="footer__socials">
          <a class="footer__social" target="_blank" href="https://vk.com/levitovchess">
            <img src="@/assets/images/vk.svg" alt="vk">
          </a>
          <a class="footer__social" target="_blank" href="https://t.me/chess">
            <img src="@/assets/images/tg.svg" alt="tg">
          </a>
          <a class="footer__social" target="_blank" href="https://www.instagram.com/levitov_chess/">
            <img src="@/assets/images/inst.svg" alt="inst">
          </a>
          <a class="footer__social" target="_blank" href="https://youtube.com/c/LevitovChess?sub_confirmation=1">
            <img src="@/assets/images/yt.svg" alt="yt">
          </a>
        </div>

        <div class="footer__img">
          <img src="@/assets/images/chess_footer_2.png" alt="chess">
        </div>
      </div>

      <div class="footer__img--mobile">
        <img src="@/assets/images/chess_footer_2.png" alt="chess">
      </div>

      <div class="footer__links">
        <a href="" class="footer__link" target="_blank">
          Политика конфиденциальности
        </a>
        <div class="footer__delimiter"/>
        <a href="" target="_blank" class="footer__link">
          Политики использования файлов cookie
        </a>
        <div class="footer__delimiter"/>
        <a href="" class="footer__link" target="_blank">
          Пользовательское соглашение
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import YTSlider from '@/components/YTSlider.vue';
import { gsap } from 'gsap';

function random(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default {
  name: 'App',
  components: {
    YTSlider,
  },
  data: () => ({
    count: 0,
    activated: false,
    animating: false,
  }),
  mounted() {
    // console.log(window.devicePixelRatio);
    // console.log(window.innerWidth);
    // console.log(window.matchMedia('(min-width: 1441px) and (max-width: 1820px)')?.matches);
    // console.log(window.matchMedia('(min-width: 1821px) and (max-width: 2048px)')?.matches);
    // console.log(window.matchMedia('(min-width: 2049px) and (max-width: 4096px)')?.matches);
    console.log('-'.repeat(26));
    console.log('--------EASTER EGG--------');
    console.log('-HINT: CHECK HTML ELEMENTS');
    console.log('-'.repeat(26));
  },
  created() {
    document.getElementById('app')
      .classList
      .add('easter-egg');
    document.body.classList.add('easter-egg');
  },
  methods: {
    joinYT() {
      // eslint-disable-next-line no-undef
      ym(91212397, 'reachGoal', 'YouTubeSub');
    },
    onClick() {
      this.count += 1;
      if (this.count < 30) return;

      const { children } = document.body.querySelector('#chess');

      if (this.activated) return;

      this.activated = true;
      this.animating = true;
      this.count = 0;

      [].forEach.call(children, (child) => {
        // eslint-disable-next-line no-param-reassign
        child.style['user-select'] = 'none';
        // eslint-disable-next-line no-param-reassign
        child.style.position = 'relative';
        // eslint-disable-next-line no-param-reassign
        child.style['z-index'] = '1';
        gsap.to(child, {
          duration: random(4, 20),
          rotate: `${random(360, 3600)}deg`,
          onComplete: () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = '/code/index.js';
            const parentNode = document.getElementsByTagName('script')[0];
            parentNode.parentNode.insertBefore(script, parentNode);
            console.log('-'.repeat(26));
            console.log('CONGRATS!');
            console.log('CLICK/HOLD-CLICK ON BACKGROUND FOR FUN :)');
            console.log('-'.repeat(26));
            this.animating = false;
          },
        });
      });
    },
    restorePage() {
      if (!this.activated || this.animating) return;

      this.activated = true;
      const { children } = document.body.querySelector('#chess');
      [].forEach.call(children, (child) => {
        // eslint-disable-next-line no-param-reassign
        child.style['user-select'] = 'initial';

        gsap.to(child, {
          duration: random(4, 20),
          rotate: '0deg',
          onComplete: () => {
            console.log('-'.repeat(26));
            console.log('IF U WANT TO RESTORE PAGE CLICK ON PHOTO');
            console.log('-'.repeat(26));
          },
        });
      });
    },
    addFirework() {

    },
  },
};
</script>

<style lang="scss">
@import "./style/mixins.scss";
@import "./style/fonts.scss";

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
}

#app {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  color: #FFF;
  font-style: normal;
  position: relative;
  text-align: start;
  font-family: 'Raleway', sans-serif;
  background-color: #08080A;
}

* {
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}

canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.chess {
  background-color: #08080A;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    width: 100%;
    @include flex-md(padding-right, 148, 40);
    @include flex-md(padding-left, 148, 40);

    @include flex-xsm(padding-right, $xsmContPad, $xsmContPad);
    @include flex-xsm(padding-left, $xsmContPad, $xsmContPad);

    @include flex-4k(padding-right, 100, $k4ContPad);
    @include flex-4k(padding-left, 100, $k4ContPad);
  }

  &__header-square {
    z-index: 3;
    position: absolute;
    top: 0;
    @include flex-md(width, 920, 700);
    @include flex-md(left, 450, 160);

    @include flex-xl(left, 370, 560);
    @include flex-xxl(left, 560, 740);

    @include flex-4k(width, 920, 2100);
    @include flex-4k(left, 600, 1280);

    @media (max-width: #{$md}px) {
      display: none;
    }
  }

  &__header-square--mobile {
    display: none;
    position: absolute;

    @include flex-xsm(width, 252, 252);
    @media (max-width: #{$md}px) {
      right: 0;
      display: initial;
    }
  }

  &__logo-container {
    padding-top: 55px;
    @include flex-md(margin-bottom, 81, 50);

    @include flex-xsm(margin-bottom, 44, 44);

    @include flex-4k(padding-top, 55, 150);
    @include flex-4k(margin-bottom, 81, 180);
  }

  &__logo {
    @include flex-md(width, 163, 130);

    @include flex-xsm(width, 125, 125);

    @include flex-4k(width, 163, 400);
  }

  .welcome {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 2;
    //margin-bottom: 150px;

    @include flex-md(margin-bottom, 170, 30);

    @include flex-xsm(margin-bottom, 40, 50);

    @include flex-xl(margin-bottom, 170, 220);

    @include flex-4k(margin-bottom, 170, 500);

    @media (max-width: #{$md}px) {
      flex-direction: column;
    }

    &__info {
      flex-grow: 1;
      @include flex-md(margin-right, 145, 20);
      @include flex-md(max-width, 478, 340);

      @include flex-xl(margin-right, 145, 165);
      @include flex-xl(max-width, 478, 550);

      @include flex-xxl(margin-right, 165, 300);
      @include flex-xxl(max-width, 550, 600);

      @include flex-4k(margin-right, 300, 400);
      @include flex-4k(max-width, 600, 1200);
    }

    &__title {
      font-weight: 700;
      @include flex-md(margin-bottom, 23, 20);
      @include flex-md(font-size, 32, 24);
      @include flex-md(line-height, 36, 26);

      @include flex-xsm(margin-bottom, 18, 18);
      @include flex-xsm(font-size, 24, 24);
      @include flex-xsm(line-height, 26, 26);

      //@include flex-xl(font-size, 46, 56);
      //@include flex-xl(line-height, 46, 60);

      //@include flex-xxl(margin-bottom, 50, 80);
      //@include flex-xxl(font-size, 56, 60);
      //@include flex-xxl(line-height, 60, 66);

      //@include flex-xxl(font-size, 56, 60);
      //@include flex-xxl(line-height, 60, 66);

      @include flex-xl(font-size, 32, 44);
      @include flex-xl(line-height, 36, 52);
      @include flex-xl(margin-bottom, 23, 50);

      @include flex-xxl(font-size, 44, 46);
      @include flex-xxl(line-height, 52, 54);
      @include flex-xxl(margin-bottom, 50, 80);

      @include flex-4k(font-size, 46, 90);
      @include flex-4k(line-height, 54, 104);
      @include flex-4k(margin-bottom, 80, 110);
    }

    &__text {
      font-weight: 500;
      @include flex-md(margin-bottom, 80, 32);

      @include flex-xsm(margin-bottom, 50, 50);

      @include flex-xl(margin-bottom, 50, 50);

      @include flex-xxl(margin-bottom, 72, 80);

      @include flex-4k(margin-bottom, 80, 180);
    }

    &__photo {
      //@include flex-md(margin-top, 4, 80);
      width: 100%;
      //@include flex-md(max-width, 370, 370);
      //@include flex-xl(max-width, 800, 756);
      //@include flex-xxl(max-width, 800, 1134);
      //@include flex-4k(max-width, 1134, 2096);
      @include media-down($md) {
        display: none;
      }
      //@include media-up($xl) {
      //  max-width: none;
      //}
    }

    &__photo--mobile {
      display: none;
      @include flex-xsm(margin-top, 66, 66);

      @media (max-width: #{$md}px) {
        display: block;
      }
    }

    &__btn-container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      @media (max-width: #{$md}px) {
        justify-content: center;
      }
    }

    &__btn {
      display: block;
      position: relative;
      font-weight: 700;
      background: #FB795D;
      box-shadow: 0 8px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      cursor: pointer;

      @include flex-md(padding-top, 20, 14);
      @include flex-md(padding-bottom, 20, 14);
      @include flex-md(padding-left, 60, 38);
      @include flex-md(padding-right, 60, 38);
      @include flex-md(font-size, 20, 16);
      @include flex-md(line-height, 24, 18);

      @include flex-xsm(padding-top, 15, 15);
      @include flex-xsm(padding-bottom, 15, 15);
      @include flex-xsm(padding-left, 45, 45);
      @include flex-xsm(padding-right, 45, 45);
      @include flex-xsm(font-size, 16, 16);
      @include flex-xsm(line-height, 24, 24);

      @include flex-4k(padding-top, 20, 60);
      @include flex-4k(padding-bottom, 20, 60);
      @include flex-4k(padding-left, 60, 180);
      @include flex-4k(padding-right, 60, 180);
      @include flex-4k(font-size, 20, 50);
      @include flex-4k(line-height, 24, 50);
    }
  }

  .about {
    display: flex;
    position: relative;
    flex-grow: 1;

    @include flex-md(padding-left, 0, 0);

    @include flex-xsm(padding-left, $xsmContPad, $xsmContPad);
    @include flex-xsm(margin-bottom, 55, 55);

    &__square {
      z-index: 0;
      position: absolute;
      width: 100%;

      @include flex-md(max-width, 620, 300);
      @include flex-md(top, -370, -100);

      @include flex-xsm(max-width, 330, 330);
      @include flex-xsm(top, 300, 300);

      @include flex-xl(max-width, 620, 820);
      @include flex-xl(top, -370, -370);

      @include flex-4k(max-width, 820, 1800);
      @include flex-4k(top, -370, -1100);
      @media (max-width: #{$md}px) {
        left: -20px;
      }
    }

    &__chess_field {
      z-index: 0;
      position: absolute;
      right: 0;
      width: 100%;
      @include flex-md(max-width, 258, 140);
      @include flex-md(top, -340, -148);
      @include flex-md(right, 5, -30);

      @include flex-xl(max-width, 258, 270);
      @include flex-xl(top, -340, -400);
      @include flex-xl(right, 5, 0);

      @include flex-xxl(top, -400, -420);

      @include flex-4k(min-width, 270, 736);
      @include flex-4k(right, 0, 0);
      @include flex-4k(top, -420, -1000);
      @include media-down($md) {
        display: none;
      }
      //@include flex-xsm(max-width, 80, 80);
      //@include flex-xsm(top, 270, 270);
      //@include flex-xsm(right, -20, -20);
    }

    &__img {
      flex-grow: 0;
      position: relative;
      //max-width: 574px;

      @include flex-md(padding-top, 70, 80);
      @include flex-md(margin-right, 240, 20);
      //@include flex-md(min-width, 574, 374);

      //@include flex-xl(min-width, 574, 800);
      //@include flex-xl(padding-top, 80, 80);
      //@include flex-xl(margin-right, 240, 240);

      @include flex-xxl(padding-top, 80, 180);
      @include flex-xxl(margin-right, 240, 300);

      @include flex-4k(padding-top, 180, 180);
      @include flex-4k(margin-right, 300, 560);

      @media (max-width: #{$md}px) {
        display: none;
      }
    }

    &__img--mobile {
      display: none;

      @include flex-xsm(margin-top, 56, 56);
      @include flex-xsm(margin-bottom, 29, 29);
      @media (max-width: #{$md}px) {
        display: block;
      }
    }

    &__content {
      position: relative;
      flex-grow: 1;
      @include flex-xsm(margin-top, 57, 57);

      @include flex-md(margin-bottom, 182, 70);
      @include flex-md(margin-right, 80, 70);
      @include flex-md(max-width, 419, 200);

      @include flex-xl(margin-right, 80, 100);
      @include flex-xl(max-width, 419, 600);

      @include flex-xxl(margin-bottom, 182, 210);

      //@include flex-xl(margin-right, 100, 170);
      //@include flex-xl(max-width, 600, 1200);

      @include flex-4k(margin-bottom, 210, 400);
    }

    &__text {
      margin-bottom: 55px;
      font-style: normal;
      font-weight: 500;

      @include flex-md(margin-bottom, 55, 55);
      @include flex-xsm(margin-bottom, 30, 30);
    }

    &__invite {
      font-weight: 700;

      //@include flex-md(font-size, 24, 18);
      //@include flex-md(line-height, 30, 21);
      //
      //@include flex-xsm(font-size, 20, 20);
      //@include flex-xsm(line-height, 23, 23);
      //
      //@include media-up($maxWidth) {
      //  @include flex-xl(font-size, 24, 32);
      //  @include flex-xl(line-height, 30, 40);
      //
      //  @include flex-4k(font-size, 32, 68);
      //  @include flex-4k(line-height, 40, 86);
      //
      //  //@include flex-xxl(font-size, 30, 34);
      //  //@include flex-xxl(line-height, 36, 44);
      //}

      @include flex-md(font-size, 20, 18);
      @include flex-md(line-height, 24, 21);

      @include flex-xsm(font-size, 20, 20);
      @include flex-xsm(line-height, 23, 23);

      @include flex-xl(font-size, 20, 26);
      @include flex-xl(line-height, 24, 36);

      @include flex-xxl(font-size, 26, 28);
      @include flex-xxl(line-height, 36, 38);

      @include flex-4k(font-size, 28, 54);
      @include flex-4k(line-height, 38, 78);
    }
  }

  .slider-container {
    width: 100%;

    @include flex-xsm(padding-left, $xsmContPad, $xsmContPad);
    @include flex-xsm(padding-right, $xsmContPad, $xsmContPad);
  }

  .btn-container {
    margin-top: 95px;
    @include flex-xsm(margin-top, 60, 95);
  }

  .footer {
    padding-right: 0;
    width: 100%;

    &__content {
      z-index: 2;
      position: relative;

      @include flex-md(padding-top, 142, 90);
      @include flex-md(padding-bottom, 208, 150);
      @include flex-xsm(padding-right, $xsmContPad, $xsmContPad);

      @include flex-xsm(padding-top, 53, 53);
      @media (max-width: #{$md}px) {
        display: flex;
        flex-direction: column;
      }

      @include flex-4k(padding-top, 142, 240);
      @include flex-4k(padding-bottom, 208, 600);
      @include flex-4k(padding-right, 100, $k4ContPad);
    }

    &__text {
      z-index: 3;
      position: relative;
      font-weight: 700;
      letter-spacing: 0.01em;

      @include flex-md(max-width, 536, 400);
      @include flex-md(font-size, 40, 32);
      @include flex-md(line-height, 48, 42);

      @include flex-xsm(font-size, 21, 21);
      @include flex-xsm(line-height, 28, 28);

      @include media-down($md) {
        text-align: center;
      }

      @include flex-4k(font-size, 46, 110);
      @include flex-4k(line-height, 59, 120);
      @include flex-4k(max-width, 536, 1640);
    }

    &__socials {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: flex-end;

      @include flex-md(margin-top, 38, 30);

      @include flex-xsm(margin-top, 25, 25);
      @include flex-xsm(width, 211, 211);
      @media (max-width: #{$md}px) {
        align-self: center;
        justify-content: space-between;
      }
      @include flex-4k(margin-top, 25, 100);
      @include flex-4k(width, 211, 1800);
    }

    &__social {
      display: block;
      transition: 0.4s ease-out;
      cursor: pointer;

      @include flex-md(width, 44, 36);
      @include flex-md(margin-right, 26, 14);

      @include flex-xsm(width, 34, 34);
      @include flex-xsm(margin-right, 34, 34);
      @media (max-width: #{$md}px) {
        margin-right: 0;
      }
      @include flex-4k(width, 44, 90);
      @include flex-4k(margin-right, 34, 80);

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__img {
      z-index: 1;
      position: absolute;
      right: 0;
      top: 28px;
      width: 100%;

      @include flex-md(max-width, 972, 550);
      @include flex-md(top, 28, 120);

      @media (max-width: #{$md}px) {
        display: none;
      }

      @include flex-4k(max-width, 972, 2400);
      @include flex-4k(top, 120, 0);
    }

    &__links {
      position: relative;
      z-index: 3;
      padding: 42px 0 46px;
      border-top: 1px solid #D9D9D9;
      display: flex;
      justify-content: center;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      color: #DADADA;

      @include flex-md(font-size, 14, 12);
      @include flex-md(line-height, 21, 18);

      @include media-down($md) {
        border: none;
        display: block;
      }
      @include media-up($maxWidth) {
        font-size: 18px;
        line-height: 22px;
      }

      @include flex-4k(font-size, 18, 36);
      @include flex-4k(line-height, 22, 48);
      @include flex-4k(padding-top, 42, 100);
      @include flex-4k(padding-bottom, 42, 100);
    }

    &__img--mobile {
      display: none;

      @media (max-width: #{$md}px) {
        display: block;
      }
    }

    &__link {
      margin: 0 25px;
      display: block;
      text-decoration: underline;
      transition: 0.4s ease-out;
      @media (max-width: #{$md}px) {
        margin-bottom: 6px;
        text-align: center;
      }
      @include flex-4k(margin-left, 25, 70);
      @include flex-4k(margin-right, 25, 70);

      &:hover {
        opacity: 0.7;
      }
    }

    &__delimiter {
      background: #979797;
      mix-blend-mode: normal;
      opacity: 0.91;
      width: 1px;
      @include flex-4k(width, 1, 3);
    }
  }

  .default-text {
    @include flex-md(font-size, 18, 16);
    @include flex-md(line-height, 22, 19);

    @include flex-xsm(font-size, 18, 18);
    @include flex-xsm(line-height, 21, 21);

    @include flex-xl(font-size, 18, 24);
    @include flex-xl(line-height, 22, 34);

    @include flex-xxl(font-size, 24, 26);
    @include flex-xxl(line-height, 34, 36);

    @include flex-4k(font-size, 26, 52);
    @include flex-4k(line-height, 36, 76);

    //@include flex-xxl(font-size, 30, 34);
    //@include flex-xxl(line-height, 36, 44);
  }
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  //padding: 15px 40px;
  display: inline-block;
  border-radius: 6px;
  transition: all .2s;
  //position: absolute;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background: #FB795D;
  color: #777;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}

.btn-white::after {
  background: #FB795D;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #FFFFFF;
}

.glue {
  white-space: nowrap;
}
</style>
