<template>
  <div class="slider">
    <div class="slider__container" ref="container">
      <div class="slider__content" ref="content">
        <div
          class="slider__item"
          v-for="(video, i) in videos"
          ref="sliderItem"
          :id="`sliderItem${i}`"
          :key="'video' + i"
        >
<!--          <embed-->
<!--            :src="video"-->
<!--            :title="'video' + i"-->
<!--            wmode="transparent"-->
<!--            type="video/mp4"-->
<!--            width="100%" height="100%"-->
<!--            allow="autoplay; encrypted-media; picture-in-picture"-->
<!--            allowfullscreen-->
<!--            allow="fullscreen;"-->
<!--          >-->
          <iframe
            :src="video"
            :title="'video' + i"
            frameborder="0"
            allow="
              accelerometer;
              autoplay;
              clipboard-write;
              encrypted-media;
              gyroscope;
              picture-in-picture
            "
            allowfullscreen
          />
        </div>
      </div>
    </div>
    <div
      class="slider__arrow-container slider__arrow--left"
      @click.prevent="swipe(-1)"
      :class="{'hidden': curIndex === 0}"
    >
      <div class="slider__arrow">
        <img src="@/assets/images/arrow.svg" alt="arrow">
      </div>
    </div>
    <div
      class="slider__arrow-container slider__arrow--right"
      @click.prevent="swipe(1)"
      :class="{'hidden': curIndex === maxIndex}"
    >
      <div class="slider__arrow">
        <img src="@/assets/images/arrow.svg" alt="arrow">
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

// const tag = document.createElement('script');
//
// tag.src = 'https://www.youtube.com/iframe_api';
// const firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//
// firstScriptTag
// // Holds a reference to the YouTube player
// let player;
//
// // this function is called by the API
// function onYouTubeIframeAPIReady() {
//   // creates the player object
//   player = new YT.Player('player');
//
//   // subscribe to events
//   player.addEventListener('onReady', 'onYouTubePlayerReady');
//   player.addEventListener('onStateChange', 'onYouTubePlayerStateChange');
// }
//
// function onYouTubePlayerReady(event) {
//   event.target.playVideo();
// }
//
// function onYouTubePlayerStateChange(event) {
//   switch (event.data) {
//     case YT.PlayerState.ENDED:
//       if ($('#link-1').length > 0) {
//         const href = $('#link-1').attr('href');
//         window.location.href = href;
//       }
//       break;
//   }
// }

export default {
  name: 'YTSlider',
  data: () => ({
    videos: [
      'https://www.youtube.com/embed/g3agnue1nik?enablejsapi=1',
      'https://www.youtube.com/embed/eIyzog0fdjI?enablejsapi=1',
      'https://www.youtube.com/embed/rrLfTdZ2jdQ?enablejsapi=1',
      'https://www.youtube.com/embed/uoEdov57mU0?enablejsapi=1',
      'https://www.youtube.com/embed/QgxVCgbBV_g',
    ],
    curIndex: 0,
    itemWidth: null,
    itemsInView: null,
    itemGap: null,
  }),
  mounted() {
    this.initSlider();
    window.addEventListener('resize', () => this.initSlider());
  },
  computed: {
    maxIndex() {
      return this.videos.length - this.itemsInView;
    },
  },
  methods: {
    initSlider() {
      this.itemWidth = gsap.getProperty(this.$refs.sliderItem[0], 'width');
      this.itemGap = gsap.getProperty(this.$refs.sliderItem[0], 'margin-right');

      const containerWidth = gsap.getProperty(this.$refs.container, 'width');
      this.itemsInView = Math.floor(containerWidth / this.itemWidth);
    },
    swipe(index) {
      this.curIndex += index;
      this.curIndex = gsap.utils.clamp(0, this.maxIndex, this.curIndex);

      gsap.to(this.$refs.content, {
        duration: 0.4,
        x: `-${this.curIndex * (this.itemWidth + this.itemGap)}`,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../style/mixins.scss";

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

.slider {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #08080A;
  &__container {
    overflow: hidden;
  }
  &__content {
    display: flex;
  }
  &__item {
    min-width: 32.5%;
    position: relative;
    margin-right: 16px;
    height: 264px;

    @include flex-xl(height, 264, 340);

    @include flex-xsm(height, 158, 370);
    @media (max-width: #{$md}px) {
      min-width: 100%;
    }
    @include flex-4k(height, 340, 900);
    @include flex-4k(margin-right, 16, 50);

    &:last-child {
      margin-right: 0;
    }
    iframe {
      display: block;
      width: 100%;
      border: 0;
      height: 100%;
    }
  }
  &__arrow-container {
    z-index: 9999;
    position: absolute;
    top: 50%;
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5555E1;
    border-radius: 6px;
    transition: 0.4s ease-out;
    cursor: pointer;

    @include flex-xsm(width, 35, 35);
    @include flex-xsm(height, 35, 35);

    @include flex-4k(width, 35, 180);
    @include flex-4k(height, 35, 180);

    &:hover {
      opacity: 0.7;
    }
  }
  &__arrow {
    width: 19px;
    @include flex-xsm(width, 12, 12);

    @include flex-4k(width, 19, 70);

    &--left {
      left: 30px;
      transform: rotate(180deg) translateY(50%);
      @include flex-xsm(left, -20, -20);

      @include flex-4k(left, 30, 80);
    }
    &--right {
      right: 30px;
      transform: translateY(-50%);
      @include flex-xsm(right, -20, -20);

      @include flex-4k(right, 30, 80);
    }
  }
}
.hidden {
  opacity: 0;
  user-select: none;
  &:hover {
    cursor: default;
    opacity: 0;
  }
}
</style>
